<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="tablefour">
        <div class="box">
            <p class="box_tit">{{time}}&nbsp;&nbsp;部门销售目标</p>
            <div class="table_tit_left">
                <p>
                    年份
                    <el-select v-model="time" @change='xuanze_nianfen_biao'>
                        <el-option
                            v-for="(i,index) in year_list"
                            :key="index"
                            :label="i"
                            :value="i">
                        </el-option>
                    </el-select>
                </p>
                <p>
                    单位：元
                </p>
            </div>
            <div class="table">
                <!-- <div class="table_left">
                    <p>添加部门</p>
                </div> -->
                <div class="table_right">
                    <p @click="dianji_guanbi">关闭</p>
                    <!-- <p @click="dianji_baocun" :style="shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'">保存</p>   shifou_baocun  true不能点false能点 -->
                    <!-- :style="shifou_baocun?'background:#B8C1CE;border:0.01rem solid #B8C1CE;':''" -->
                    <p @click="dianji_chutu" :style="time.length!=0?'background:#9a86db;color:#fff;border:0.01rem solid #9a86db;margin-right:0;':'margin-right:0;'">出图</p>
                    <!-- :style="zhanshi==1?'background:#9a86db;color:#fff;border:0.01rem solid #9a86db;margin-right:0;':'margin-right:0;'"  -->
                </div>
            </div>
            <div class="table_con">
                <el-table :data="neirong_list"
                    border
                    max-height="600"
                    :cell-style="cellStyle"
                    :header-cell-style="headerCellStyle"
                    >
                    <el-table-column v-for="(item, index) in biaotou_list"
                        :key="`col_${index}`"
                        :prop="biaotou_list[index].con"
                        width="120"
                        fixed
                        :label="item.name">
                    </el-table-column>
                    <el-table-column v-for="(item, index) in biaotou_list2"
                        :key="index"
                        :label="item.name"
                        >
                        <template slot="header" slot-scope="{ column, $index }">
                            <div class="tiaozheng_biaotou">
                                <p @click="dianji_tiaozheng(item.con)">调整目标</p>
                                {{column.label}}
                                <el-tooltip class="item" effect="dark" content="查询本部门历史数据" placement="left">
                                    <i @click="dianji_chaxun_lishi(item.con)" style="color:#A6C6FC;height:0.22rem;width:0.22rem;font-size:0.22rem;" class="el-icon-date"></i>
                                </el-tooltip>
                            </div>
                        </template>
                        <!--
                            width="250" -->
                        <el-table-column v-for="(i, ind) in item.list"
                            :key="`col_${ind}`"
                            :prop="item.list[ind].con"
                            min-width="250"
                            :label="i.name">
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div class="tiaozheng_mubiao" v-if="tiaozheng_list">
                <div class="tiaozheng_box" @click.stop>
                    <div class="tiaozheng_con">
                        <p class="tiaozheng_tit">调整目标</p>
                        <div class="tiaozheng_tit_right">
                            <!-- <p>筛选</p> -->
                            <p>单位 : 元</p>
                        </div>
                        <div class="table">
                            <p :style="tiaozheng_shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'" @click="tiaozheng_baocun">保存</p>
                            <p @click="tiaozheng_list=false" style="margin-right:0;">关闭</p>
                        </div>
                        <div class="table_con">
                            <el-table :data="tiaozheng_neirong"
                                border
                                max-height="600"
                                :cell-style="cellStyle_tiao"
                                :header-cell-style="headerCellStyle"
                                >
                                <el-table-column>
                                    <template slot="header">
                                        <div style="text-align:center;">
                                            {{tiaozheng_bumen_name}}
                                        </div>
                                    </template>
                                    <el-table-column v-for="(i, ind) in tiaozheng_biaotou"
                                        :key="`col_${ind}`"
                                        :prop="tiaozheng_biaotou[ind].con"
                                        :label="i.name">
                                        <template slot-scope="props">
                                            {{tiaozheng_biaotou[ind].con!='zhi'&&props.row.prod_code!='合计'?props.row[tiaozheng_biaotou[ind].con]:''}}
                                            {{props.row.prod_code=='合计'?props.row[tiaozheng_biaotou[ind].con]:''}}
                                            <input type="number" v-if="props.row.prod_code!='合计'&&tiaozheng_biaotou[ind].con=='zhi'" v-model="props.row[tiaozheng_biaotou[ind].con]">
                                        </template>
                                    </el-table-column>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lishi_chaxun" v-if="lishi_yemian">
                <div class="lishi_chaxun_box">
                    <div class="lishi_chaxun_con">
                        <p class="lishi_chaxun_tit">历史目标调整数据</p>
                        <div class="lishi_chaxun_tit_right">
                            <!-- <p>筛选</p> -->
                            <p>单位 : 元</p>
                        </div>
                        <div class="table">
                            <p style="margin-right:0;" @click="lishi_yemian=false">关闭</p>
                        </div>
                        <div class="table_con">
                            <el-table :data="lishi_list"
                                border
                                max-height="600"
                                :cell-style="cellStyle_tiao2"
                                :header-cell-style="headerCellStyle"
                                >
                                <el-table-column>
                                    <template slot="header">
                                        <div style="text-align:center;">
                                            {{tiaozheng_bumen_name}}
                                        </div>
                                    </template>
                                    <el-table-column v-for="(i, ind) in lishi_biaotou"
                                        :key="`col_${ind}`"
                                        :prop="lishi_biaotou[ind].con"
                                        :label="i.name">
                                        <!-- <template slot-scope="props">
                                            {{tiaozheng_biaotou[ind].con!='zhi'&&props.row.prod_code!='合计'?props.row[tiaozheng_biaotou[ind].con]:''}}
                                            {{props.row.prod_code=='合计'?props.row[tiaozheng_biaotou[ind].con]:''}}
                                            <input type="number" v-if="props.row.prod_code!='合计'&&tiaozheng_biaotou[ind].con=='zhi'" v-model="props.row[tiaozheng_biaotou[ind].con]">
                                        </template> -->
                                    </el-table-column>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tusi ref="mubiao_tu4"></tusi>
    </div>
</template>

<script>
import tusi from '../Figures/Figurefour'
import { query_as_dept_aim_log, update_as_dept_aim, query_ent_dept_list_aim_tile, query_as_year_list, supply_as_dept_aim_data } from '../../api/api.js'
export default {
  name: 'tablefour',
  data () {
    return {
      time: '',
      year_list: [],
      shifou_baocun: false,
      neirong_list: [],
      biaotou_list: [
        {
          name: '项目',
          con: 'prod_name'
        },
        {
          name: '本年目标销售额',
          con: 'amt_aim'
        },
        {
          name: '本年目标销售额(已分配)',
          con: 'amt_aim_have'
        },
        {
          name: '本年目标销售额(未分配)',
          con: 'amt_aim_will'
        }
      ],
      biaotou_list2: [],
      tiaozheng_list: false,
      tiaozheng_bumen_id: '', // 查询调整部门id
      tiaozheng_bumen_name: '', // 查询调整部门名字
      tiaozheng_biaotou: [],
      tiaozheng_neirong: [],
      xinxi: '', // 查询当前页面的数据的接口
      tiaozheng_shifou_baocun: '', // 调整是否可以保存
      lishi_yemian: false,
      lishi_list: [],
      lishi_tit: [],
      lishi_biaotou: [],
      lishi_neirong: []
    }
  },
  created () {
    this.jichu()
  },
  mounted () {
    this.$refs.mubiao_tu4.biaoge_dakai()
  },
  watch: {
    //   zidong_tiaozheng_hou
    // 监听调整内容
    tiaozheng_neirong: {
      handler (newValue, oldValue) {
        this.zidong_tiaozheng_hou()
        this.panduan_baocun()
      },
      deep: true
    }
  },
  components: { tusi },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '4'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
            date.forEach(it => {
              if (it == new Date().getFullYear()) {
                this.time = new Date().getFullYear() + '年'
                this.xuanze_nianfen_biao()
              }
            })
          }
        } else if (res.data.code == 10238) {}
      })
      this.bumen_liebiao_jiekou()
    },
    // 部门列表接口
    bumen_liebiao_jiekou () {
      query_ent_dept_list_aim_tile({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)

          if (date != []) {
            this.biaotou_list2 = []
            for (let i = 0; i < date.length; i++) {
              this.biaotou_list2.push({
                name: date[i].label,
                con: date[i].dept_id,
                list: [
                  // {
                  //     name:'本年目标销售额',
                  //     con:date[i].dept_id+'_start'
                  // },
                  {
                    name: '调整后本年目标销售额',
                    con: date[i].dept_id + '_end'
                  }
                ]
              })
            }
          }
        } else if (res.data.code == 10169) {}
      })
    },
    // 选择年份
    xuanze_nianfen_biao () {
      console.log(this.time)
      this.chaxun_jiekou()
      // this.chanpin_liebiao_jiekou()
    },
    // 查询接口
    chaxun_jiekou () {
      supply_as_dept_aim_data({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.xinxi = date
          if (date.length > 1) {
            this.neirong_list = []
            const obj1 = {
              prod_name: '编制人数',
              amt_aim: '',
              amt_aim_have: '',
              amt_aim_will: ''
            }
            const obj2 = { // 合计
              prod_name: '合计',
              amt_aim: '',
              amt_aim_have: '',
              amt_aim_will: ''
            }
            const obj3 = { // 人均产值
              prod_name: '人均产值',
              amt_aim: '',
              amt_aim_have: '',
              amt_aim_will: ''
            }
            console.log(this.biaotou_list2)
            for (let i = 0; i < this.biaotou_list2.length; i++) {
              obj1[[this.biaotou_list2[i].con] + '_start'] = date[0].map[this.biaotou_list2[i].con] != undefined ? date[0].map[this.biaotou_list2[i].con] : '0' + '人'
              obj1[[this.biaotou_list2[i].con] + '_end'] = date[0].map[this.biaotou_list2[i].con] != undefined ? date[0].map[this.biaotou_list2[i].con] : '0' + '人'
              for (let a = 0; a < date.length; a++) {
                console.log(date[a].map[[this.biaotou_list2[i].con] + '_start'])
                if (!isNaN(Number(obj2[[this.biaotou_list2[i].con] + '_start']))) {
                  obj2[[this.biaotou_list2[i].con] + '_start'] = Number(date[a].map[[this.biaotou_list2[i].con] + '_start'] != undefined ? date[a].map[[this.biaotou_list2[i].con] + '_start'] : 0) + Number(obj2[[this.biaotou_list2[i].con] + '_start'])
                } else {
                  obj2[[this.biaotou_list2[i].con] + '_start'] = Number(date[a].map[[this.biaotou_list2[i].con] + '_start'] != undefined ? date[a].map[[this.biaotou_list2[i].con] + '_start'] : 0)
                }
                if (!isNaN(Number(obj2[[this.biaotou_list2[i].con] + '_end']))) {
                  obj2[[this.biaotou_list2[i].con] + '_end'] = Number(date[a].map[[this.biaotou_list2[i].con] + '_end'] != undefined ? date[a].map[[this.biaotou_list2[i].con] + '_end'] : 0) + Number(obj2[[this.biaotou_list2[i].con] + '_end'])
                } else {
                  obj2[[this.biaotou_list2[i].con] + '_end'] = Number(date[a].map[[this.biaotou_list2[i].con] + '_end'] != undefined ? date[a].map[[this.biaotou_list2[i].con] + '_end'] : 0)
                }
              }
              console.log(date[0].map[this.biaotou_list2[i].con])
              console.log(obj2[[this.biaotou_list2[i].con] + '_start'])
              obj3[[this.biaotou_list2[i].con] + '_start'] = date[0].map[this.biaotou_list2[i].con] != undefined && obj2[[this.biaotou_list2[i].con] + '_start'] != 0 ? Number(obj2[[this.biaotou_list2[i].con] + '_start']) / Number(date[0].map[this.biaotou_list2[i].con]) : '0'
              obj3[[this.biaotou_list2[i].con] + '_end'] = obj2[[this.biaotou_list2[i].con] + '_end'] != 0 && date[0].map[this.biaotou_list2[i].con] != undefined ? Number(obj2[[this.biaotou_list2[i].con] + '_end']) / Number(date[0].map[this.biaotou_list2[i].con]) : '0'
              obj3[[this.biaotou_list2[i].con] + '_start'] = this.$qianwei(Number(obj3[[this.biaotou_list2[i].con] + '_start']).toFixed(2))
              obj3[[this.biaotou_list2[i].con] + '_end'] = this.$qianwei(Number(obj3[[this.biaotou_list2[i].con] + '_end']).toFixed(2))
              obj2[[this.biaotou_list2[i].con] + '_start'] = this.$qianwei(Number(obj2[[this.biaotou_list2[i].con] + '_start']).toFixed(0))
              obj2[[this.biaotou_list2[i].con] + '_end'] = this.$qianwei(Number(obj2[[this.biaotou_list2[i].con] + '_end']).toFixed(0))
            }
            this.neirong_list.unshift(obj1)
            this.neirong_list.push(obj3)

            for (let i = 1; i < date.length; i++) {
              console.log(Number(date[i].amt_aim))
              // console.log(Number(obj2.amt_aim)+Number(date[i].amt_aim))
              // +Number(obj2.amt_aim)
              obj2.amt_aim = Number(obj2.amt_aim) + Number(date[i].amt_aim)
              console.log(obj2.amt_aim)
              obj2.amt_aim_have = Number(date[i].amt_aim_have) + Number(obj2.amt_aim_have)
              obj2.amt_aim_will = Number(date[i].amt_aim_will) + Number(obj2.amt_aim_will)
              Object.assign(date[i], date[i].map)
              const obj4 = {
                prod_name: date[i].prod_name,
                amt_aim: this.$qianwei(Number(date[i].amt_aim).toFixed(0)),
                amt_aim_have: this.$qianwei(Number(date[i].amt_aim_have).toFixed(0)),
                amt_aim_will: this.$qianwei(Number(date[i].amt_aim_will).toFixed(0))
              }
              for (let a = 0; a < this.biaotou_list2.length; a++) {
                obj4[[this.biaotou_list2[a].con] + '_start'] = this.$qianwei(Number(date[i][[this.biaotou_list2[a].con] + '_start']).toFixed(0)) != 'NaN' ? this.$qianwei(Number(date[i][[this.biaotou_list2[a].con] + '_start']).toFixed(0)) : ''
                obj4[[this.biaotou_list2[a].con] + '_end'] = this.$qianwei(Number(date[i][[this.biaotou_list2[a].con] + '_end']).toFixed(0)) != 'NaN' ? this.$qianwei(Number(date[i][[this.biaotou_list2[a].con] + '_end']).toFixed(0)) : ''
              }
              this.neirong_list.push(obj4)
            }
            console.log(this.neirong_list)
            obj2.amt_aim = this.$qianwei(Number(obj2.amt_aim).toFixed(0))
            obj2.amt_aim_have = this.$qianwei(Number(obj2.amt_aim_have).toFixed(0))
            obj2.amt_aim_will = this.$qianwei(Number(obj2.amt_aim_will).toFixed(0))
            this.neirong_list.push(obj2)
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 点击调整接口
    dianji_tiaozheng (id) {
      console.log(id)
      if (this.neirong_list.length != 0) {
        this.tiaozheng_bumen_id = id
        this.tiaozheng_list = true
        for (let i = 0; i < this.biaotou_list2.length; i++) {
          if (id == this.biaotou_list2[i].con) {
            this.tiaozheng_bumen_name = this.biaotou_list2[i].name
          }
        }
        this.tiaozheng_biaotou = [
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '本年目标销售额',
            con: id + '_end'
          },
          {
            name: '本年目标销售额调整',
            con: 'zhi'
          },
          {
            name: '调整后本年目标销售额',
            con: 'zhi_end'
          }
        ]
        this.tiaozheng_neirong = []
        let zhi1 = ''
        for (let i = 1; i < this.xinxi.length; i++) {
          if (this.xinxi[i][id + '_end'] != undefined) {
            this.tiaozheng_neirong.push({
              prod_name: this.xinxi[i].prod_name,
              prod_code: this.xinxi[i].prod_code,
              prod_id: this.xinxi[i].prod_id,
              [id + '_end']: this.$qianwei((this.xinxi[i][id + '_end']).toFixed(0)),
              zhi: '',
              zhi_end: ''
            })
          }
        }
        for (let i = 0; i < this.tiaozheng_neirong.length; i++) {
          zhi1 = Number(zhi1) + this.$shuzi(this.tiaozheng_neirong[i][id + '_end'])
        }
        this.tiaozheng_neirong.push({
          prod_name: '',
          prod_code: '合计',
          prod_id: '',
          [id + '_end']: this.$qianwei(zhi1.toFixed(0)),
          zhi: '',
          zhi_end: ''
        })
      } else {
        this.$message({
          message: '您当前没有产品',
          type: 'warning'
        })
      }
    },
    // 自动计算
    zidong_tiaozheng_hou () {
      if (this.tiaozheng_neirong.length != 0) {
        let zhi = ''
        let zhi_end = ''
        for (let i = 0; i < this.tiaozheng_neirong.length - 1; i++) {
          this.tiaozheng_neirong[i].zhi_end = this.$qianwei((Number(this.tiaozheng_neirong[i].zhi) + this.$shuzi(this.tiaozheng_neirong[i][this.tiaozheng_bumen_id + '_end'])).toFixed(0))
          zhi = Number(zhi) + Number(this.tiaozheng_neirong[i].zhi)
          zhi_end = Number(zhi_end) + this.$shuzi(this.tiaozheng_neirong[i].zhi_end)
        }
        this.tiaozheng_neirong[this.tiaozheng_neirong.length - 1].zhi = this.$qianwei(zhi.toFixed(0))
        this.tiaozheng_neirong[this.tiaozheng_neirong.length - 1].zhi_end = this.$qianwei(zhi_end.toFixed(0))
      }
    },
    // 点击关闭
    dianji_guanbi () {
      this.$router.push('/aspreackeven')
    },
    // 点击保存
    dianji_baocun () {

    },
    // 点击历史
    dianji_chaxun_lishi (id) {
      console.log(id)
      if (this.neirong_list.length != 0) {
        this.tiaozheng_bumen_id = id
        for (let i = 0; i < this.biaotou_list2.length; i++) {
          if (id == this.biaotou_list2[i].con) {
            this.tiaozheng_bumen_name = this.biaotou_list2[i].name
          }
        }
        this.lishi_yemian = true
        this.lishi_biaotou = [
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        query_as_dept_aim_log({
          data: {
            ent_id: this.$ent_id(),
            year: this.time.slice(0, 4),
            dept_id: id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            const data = JSON.parse(res.data.body.date_list)
            console.log(date)
            console.log(data)
            for (let i = 0; i < date.length; i++) {
              for (var a in date[i]) {
                if (date[i][a] == '-123456789.00') {
                  date[i][a] = ''
                }
              }
            }
            for (let i = 0; i < data.length; i++) {
              this.lishi_biaotou.push({
                name: '本年目标销售额调整(' + data[i].slice(0, 4) + '-' + data[i].slice(4, 6) + '-' + data[i].slice(6) + ')',
                con: '调整金额' + data[i]
              })
              this.lishi_biaotou.push({
                name: '调整后本年目标销售额(' + data[i].slice(0, 4) + '-' + data[i].slice(4, 6) + '-' + data[i].slice(6) + ')',
                con: data[i]
              })
            }
            date.push({
              prod_name: '',
              prod_code: '合计',
              amt_aim: ''
            })
            for (let i = 0; i < data.length; i++) {
              date[date.length - 1]['调整金额' + [data[i]]] = ''
              date[date.length - 1][data[i]] = ''
            }
            let heji_bennian_jine = ''
            for (let i = 0; i < date.length - 1; i++) {
              // if(!isNaN(date[date.length-1].amt_aim)){
              //     date[date.length-1].amt_aim=this.$qianwei((Number(date[i].amt_aim)+Number(date[date.length-1].amt_aim)).toFixed(2))
              // }else{
              //     date[date.length-1].amt_aim=this.$qianwei(Number(date[i].amt_aim).toFixed(2))
              // }
              heji_bennian_jine = this.$shuzi(date[i].amt_aim) + Number(heji_bennian_jine)
              // this.$qianwei((Number(date[i].amt_aim)+Number(date[date.length-1].amt_aim)).toFixed(2))
              for (let a = 0; a < data.length; a++) {
                date[i]['调整金额' + [data[a]]] = this.$qianwei(Number(date[i]['调整金额' + [data[a]]]).toFixed(0))
                date[i][data[a]] = this.$qianwei(Number(date[i][data[a]]).toFixed(0))
                let zhi = 0
                let zhi2 = 0
                for (let b = 0; b < date.length - 1; b++) {
                  zhi = Number(zhi) + this.$shuzi(date[b]['调整金额' + [data[a]]])
                  zhi2 = Number(zhi2) + this.$shuzi(date[b][data[a]])
                }
                date[date.length - 1]['调整金额' + [data[a]]] = this.$qianwei(zhi.toFixed(0))
                date[date.length - 1][data[a]] = this.$qianwei(zhi2.toFixed(0))
              }
              date[i].amt_aim = this.$qianwei(Number(date[i].amt_aim).toFixed(0))
            }
            date[date.length - 1].amt_aim = this.$qianwei(Number(heji_bennian_jine).toFixed(0))
            this.lishi_list = date
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '您当前没有产品',
          type: 'warning'
        })
      }
    },
    dianji_chutu () {
      if (this.time.length != 0) {
        // this.chuxian='111'
        // this.quan=this.time
        // console.log(this.chuanshu)

        this.$refs.mubiao_tu4.dakai_quanping(
          {
            time: this.time,
            chutu: true,
            biaoge_nei: true
          }
        )
        // this.chutu=false
      } else {
        this.$message({
          message: '选择年份',
          type: 'warning'
        })
      }
    },
    // 调整保存
    tiaozheng_baocun () {
      if (this.tiaozheng_shifou_baocun) {
        const cuowu = []
        this.tiaozheng_neirong.forEach(item => {
          if (item.zhi.length != 0) {
            if (this.$shuzi(item.zhi_end) < 0) {
              cuowu.push('111')
            }
          }
        })
        if (cuowu.length == 0) {
          const list = []
          for (let i = 0; i < this.tiaozheng_neirong.length - 1; i++) {
            if (this.tiaozheng_neirong[i].zhi != '') {
              list.push({
                dept_id: this.tiaozheng_bumen_id,
                prod_code: this.tiaozheng_neirong[i].prod_code,
                prod_id: this.tiaozheng_neirong[i].prod_id,
                amt_adjust: this.tiaozheng_neirong[i].zhi,
                amt_aim: this.$shuzi(this.tiaozheng_neirong[i].zhi_end) + ''
              })
            }
          }
          update_as_dept_aim({
            data: {
              user_id: this.$jichuxinxi().user_id,
              ent_id: this.$ent_id(),
              year: this.time.slice(0, 4),
              dept_id: this.tiaozheng_bumen_id,
              list: list
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.tiaozheng_list = false
              this.jichu()
              this.chaxun_jiekou()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.$message({
            message: '目标不能为负值',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请输入需要调整产品的目标',
          type: 'warning'
        })
      }
    },
    // 判断是否可以点调整保存
    panduan_baocun () {
      const shifou = []
      for (let i = 0; i < this.tiaozheng_neirong.length - 1; i++) {
        if (this.tiaozheng_neirong[i].zhi == '' || this.tiaozheng_neirong[i].zhi == undefined) {
          shifou.push(1)
        }
      }
      console.log(this.tiaozheng_neirong)
      if (shifou.length != this.tiaozheng_neirong.length - 1) {
        this.tiaozheng_shifou_baocun = true
      } else {
        this.tiaozheng_shifou_baocun = false
      }
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;width:2.5rem;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    cellStyle (row) {
      if (row.row.prod_name != undefined) {
        if (row.row.prod_name == '合计' && row.column.property == 'prod_name') {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:center;background:#D8E6F7;width:2.5rem;'
        }
        if (row.column.property != 'prod_name') {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:right;background:#E2EFDA;width:2.5rem;'
        } else {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
        }
      }
    },
    cellStyle_tiao (row) {
      // console.log(row)
      // if(row.col.zhi!=undefined){
      if (row.column.label.indexOf('本年目标销售额调整') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;background:#E2EFDA;width:2.5rem;'
      }
      if (row.column.label.indexOf('调整后本年目标销售额') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;background:#E2EFDA;width:2.5rem;'
      }
      if (row.column.label == '本年目标销售额' && row.row.prod_code == '合计') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;background:#E2EFDA;'
      }
      if (row.column.label == '本年目标销售额') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      }
      if (row.row.prod_code == '合计') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;background:#E2EFDA;width:2.5rem;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
      // }
    },
    cellStyle_tiao2 (row) {
      console.log(row)
      // if(row.col.zhi!=undefined){
      if (row.row.prod_code == '合计' && row.column.label == '产品编号') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;background:#D8E6F7;width:2.5rem;'
      }
      if (row.row.prod_code == '合计' && row.column.label != '产品编号') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;background:#E2EFDA;width:2.5rem;'
      }
      if (row.column.label == '本年目标销售额') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      }
      if (row.column.label.indexOf('本年目标销售额调整') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      }
      if (row.column.label.indexOf('调整后本年目标销售额') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
      // }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tablefour {
    min-height:7.2rem;
    background:#F4F7FD;
    padding: 0.31rem 0.31rem 0.34rem 0.31rem;
    // width:100%;
    .box{
        min-height:7.2rem;
        width:17.2rem;
        .box_tit{
            font-size: 0.22rem;
            color:#1A2533;
            text-align: center;
            margin-bottom: 0.33rem;
        }
        .table_tit_left{
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                font-size: 0.14rem;
                color:#4c4a4d;
            }
        }
        .table{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height:0.69rem;
            padding: 0 0.21rem;
            border:0.01rem solid #888;
            margin-top:0.2rem;
            div{
                display: flex;
                align-items: center;
                p{
                    padding: 0.03rem 0.16rem;
                    color:#838383;
                    font-size: 0.14rem;
                    border-radius: 0.02rem;
                    border:0.01rem solid #979797;
                    margin-right:0.12rem;
                    cursor: pointer;
                }
            }
            // .table_left{
            //     p{
            //         background: #9a86db;
            //         color:#fff;
            //     }
            // }
            .table_right{
                p{
                    // &:nth-child(2){
                    //     background:#9a86db;
                    //     color:#fff;
                    //     border:0.01rem solid #9a86db;
                    // }
                    &:nth-child(2){
                        background:#B8C1CE;
                        color:#fff;
                        border:0.01rem solid #B8C1CE;
                    }
                }
            }
        }
        .table_con{
            .tiaozheng_biaotou{
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    height:0.2rem;
                    width:0.72rem;
                    border:0.01rem solid #888888;
                    border-radius: 0.02rem;
                    font-size: 0.1rem;
                    color:#888888;
                    line-height: 0.2rem;
                }
            }
        }
        .tiaozheng_mubiao{
            height:100%;
            width:100%;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            margin: 0;
            z-index:999;
            background: rgba(0,0,0,0.8);
            .tiaozheng_box{
                height:9rem;
                width:14.46rem;
                border-radius: 0.04rem;
                background:#fff;
                position: relative;
                left: 50%;
                top:50%;
                margin-left: -7.23rem;
                margin-top: -4.5rem;
                .tiaozheng_con{
                    padding-top:0.63rem;
                    width:12.46rem;
                    margin: 0 auto;
                    .tiaozheng_tit{
                        font-size: 0.18rem;
                        color:#1A2533;
                        margin-bottom: 0.28rem;
                        text-align: center;
                    }
                    .tiaozheng_tit_right{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        p{
                            color:#4C4A4D;
                            &:nth-child(1){
                                font-size: 0.14rem;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                            }
                        }
                    }
                    .table{
                        height:0.69rem;
                        padding: 0 0.21rem;
                        border:0.01rem solid #888;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-top:0.2rem;
                        p{
                            padding: 0.03rem 0.16rem;
                            color:#838383;
                            font-size: 0.14rem;
                            border-radius: 0.02rem;
                            border:0.01rem solid #979797;
                            margin-right:0.12rem;
                            cursor: pointer;
                            &:nth-child(1){
                                background:#9a86db;
                                color:#fff;
                                border:0.01rem solid #9a86db;
                            }
                            &:nth-child(2){
                                background:#B8C1CE;
                                color:#fff;
                                border:0.01rem solid #B8C1CE;
                            }
                        }
                    }
                }
            }
        }
        .lishi_chaxun{
            height:100%;
            width:100%;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            margin: 0;
            z-index:999;
            background: rgba(0,0,0,0.8);
            .lishi_chaxun_box{
                height:9rem;
                width:14.46rem;
                border-radius: 0.04rem;
                background:#fff;
                position: relative;
                left: 50%;
                top:50%;
                margin-left: -7.23rem;
                margin-top: -4.5rem;
                .lishi_chaxun_con{
                    padding-top:0.63rem;
                    width:12.46rem;
                    margin: 0 auto;
                    .lishi_chaxun_tit{
                        font-size: 0.18rem;
                        color:#1A2533;
                        margin-bottom: 0.28rem;
                        text-align: center;
                    }
                    .lishi_chaxun_tit_right{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        p{
                            color:#4C4A4D;
                            &:nth-child(1){
                                font-size: 0.14rem;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                            }
                        }
                    }
                    .table{
                        height:0.69rem;
                        padding: 0 0.21rem;
                        border:0.01rem solid #888;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-top:0.2rem;
                        p{
                            padding: 0.03rem 0.16rem;
                            color:#444;
                            font-size: 0.14rem;
                            border-radius: 0.02rem;
                            border:0.01rem solid #979797;
                            margin-right:0.12rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .box {
            width:100%;
        }
    }
}
</style>
